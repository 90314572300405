<template>
  <div class="white grid justify-items-center px-0 py-10 text-left">
		<v-col cols="10" sm="6" class="pa-0 mt-10 pt-10">
			<div class="font-bold text-2xl sm:text-4xl mt-5 mb-8" v-if="bahasa">Hasil Pencarian</div>
			<div class="font-bold text-2xl sm:text-4xl mt-5 mb-8" v-else>Search Result</div>
			<v-row class="mb-10 mt-10">
				<v-col cols="9">
					<v-text-field
						v-model="search"
						placeholder="Search"
						solo
						hide-details
						class="search-field"
					/>
				</v-col>
				<v-col cols="3">
					<v-btn block class="btn-primary font-bold" @click="getData()">
						{{bahasa ? 'Cari' : 'Search'}}
					</v-btn>
				</v-col>
			</v-row>
			<v-skeleton-loader
				type="paragraph"
				class="mt-5"
				v-if="isLoad"
			></v-skeleton-loader>
			<div v-if="items.length > 0">
				<div v-for="(i, index) in items" :key="index" class="cursor-pointer" @click="redirectPage(i)">
					<div class="font-bold text-base sm:text-xl mt-5 text-left">{{i.name ? i.name : i.title ? i.title : about.includes(i.type)  ? 'About Us' : '-'}}</div>
					<div class="text-sm py-4 text-capitalize">{{i.type || '-'}} - {{i.lastUpdate || '-'}}</div>
					<span v-html="i.description ? strLimitt(i.description, 400) : strLimitt(i.desc, 400)" />
					<hr class="hr-expansion">
				</div>
			</div>
		</v-col>
		<v-col cols="10" class="pa-0 mt-10">
			<v-row v-if="items.length === 0">
				<v-col cols="12" sm="6">
					<img src="@/assets/images/405.png" width="100%"/>
				</v-col>
				<v-col cols="12" sm="6" class="text-black flex flex-wrap content-center">
					<div class="text-3xl font-bold">Data tidak ditemukan</div>
					<div class="text-lg mt-3">Kata kunci yang anda cari tidak ditemukan</div>
					<!-- <v-btn class="btn-primary mt-8">
						{{bahasa ? 'kebmali ke beranda' : 'back to home'}}
					</v-btn> -->
				</v-col>
			</v-row>
		</v-col>
	</div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
	props: {
		bahasa: {
			type: Boolean,
			default: true
		},
		search: {
			type: String,
			require: false
		}
	},
	data() {
		return {
			searchKey: '',
			page: 1,
			isLoad: false,
			items: [],
			baseUrl: process.env.VUE_APP_BASE_URL,
			about: ['ABOUT_US', 'ORGANIZATION', 'STEERING_COMMITTED', 'PROCESS', 'BOARD']
		}
	},
	computed: {
    language() {
      return this.bahasa ? 'ID' : 'EN';
    }
  },
	watch: {
		bahasa() {
			this.getData();
		},
		search() {
			clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getData();
        }, 1000);
		}
	},
	async mounted() {
		await this.getData();
	},
	methods: {
		async getData() {
			this.isLoad = true;
			let param = {
				lang: this.language,
        search: this.search
			}
      await axios.get(this.baseUrl + '/search', {params: param}).then(response => {
        if (response.data.code === 200) {
          this.items = response.data.data ? response.data.data : [];
        }
      })
      .catch(error => {
				Swal.fire('Failed!', error.response.data.message, 'error');
      })
      .finally(() => this.isLoad = false);
		},
		strLimitt(val, max = 60, extra = '...') {
      if (val && val.length > max) {
        return val.slice(0, max) + extra;
      }
      return val;
    },
		redirectPage(item) {
			let namePage = '';
			switch(item.type.toLowerCase()) {
				case 'event' :
					namePage = 'event-detail';
					break;
				case 'service' :
					namePage = 'service';
					break;
				case 'kisah' :
					namePage = 'content-detail';
					break;
				case 'blog' :
					namePage = 'content-detail';
					break;
				case 'berita' :
					namePage = 'news-detail';
					break;
				case 'career' :
					namePage = 'career-detail';
					break;
				case 'performance' :
					namePage = 'performances-detail';
					break;
				case 'procurement' :
					namePage = 'procurement-detail';
					break;
				case 'publication' :
					namePage = 'public-detail';
					break;
				case 'donor' :
					namePage = 'donor-detail';
					break;
				case 'information_public' :
					namePage = 'public-information';
					break;
				case 'program' :
					namePage = 'program-detail';
					break;
				case 'sector' :
					namePage = 'sector-detail';
					break;
				case 'hukum' :
					namePage = 'legal';
					break;
				case 'report' :
					namePage = 'report-detail';
					break;
				case 'about_us' :
					namePage = 'about_us';
					break;
				case 'contact' :
					namePage = 'contact-us';
					break;
				case 'privacy_policy' :
					namePage = 'privacy-policy';
					break;
				case 'tnc' :
					namePage = 'terms-&-conditions';
					break;
				case 'board' :
					namePage = 'about_us';
					break;
				case 'organization' :
					namePage = 'about_us';
					break;
				case 'process' :
					namePage = 'about_us';
					break;
				case 'streering_committed' :
					namePage = 'about_us';
					break;
			}
			this.goTo(namePage, item.id, item.type);
		},
		goTo(link, param, type) {
			this.$router.push({
        name: link,
				params: {
					id: param,
					type: '/' + type.toLowerCase()
				}
      });
		},
	}
}
</script>